/* main */

.listGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 15px;    
    @media only screen and (min-width: 450px) {
        grid-template-columns: 1fr 1fr ;
    }
    @media only screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.listGrid > div {
    display: block;    
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid $c-back;
    background-color: $c-blue;
    box-shadow: 1px 2px 56px 0px rgba(0,0,0,0.2);

    & h2 {
        color: $c-white;
        text-align: left;
        margin-bottom: 0px;
    }

    & a {
        color: $c-white;
    }
    &:hover {
        box-shadow: 1px 2px 56px 0px rgba(0,0,0,0.5);
    }
}

.eventForm {
    display: block;
    padding: 15px;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    border: 2px solid $c-back;
    background-color: $c-back;
    box-shadow: 1px 2px 56px 0px rgba(0,0,0,0.2);
    margin-bottom: 40px;
   
}

.eventForm2C {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    @media only screen and (min-width: 650px) {
        flex-wrap: nowrap;
        gap: 10px;
    }
    & > div {
        width: 100%;
        @media only screen and (min-width: 650px) {
            flex: 1;
            width: auto;
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
}

.header h2 {
    color: $c-blue;
    font-weight: 900;
    font-size: 1.5rem;
    @media screen and (min-width: 650px){
        font-size: 2rem;
    }
}

.header h2 span {
    display: block;
    color: $c-txt;
    font-weight: 500;
    font-size: 1rem;
    text-align: left;
    margin-top: -10px;
}

.nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    gap: 10px;
}


.nav li {
    display: block;
}

.nav li a {
    text-indent: 10000px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    display: block;
    background-position: center center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
}

.nav li a.nav_list {
    background-image: url(./img/calendar.svg);
}
.nav li a.nav_pozice {
    background-image: url(./img/emploee.svg);
}
.nav li a.nav_exit {
    background-image: url(./img/exit.svg);
}

.detaiH1 {
    margin-bottom: 0px;
}

.detaiH2 {
    margin-bottom: 10px;
}


.manItem {
    padding: 15px;
    border-radius: 5px;
    background-color: $c-blue;
    color: $c-white;
    display: flex;
    gap: 10px;
    font-size: 1.1rem;
    flex-wrap: wrap;
    margin-bottom: 10px;
    @media screen and (min-width: 650px){
        flex-wrap: nowrap;
    }
}
.manItem span {
    font-size: 1.3rem;
}
.manItemTxt {
    width: 100%;
    @media screen and (min-width: 650px){
        flex: 1;
        width: auto;
    }
}
.manItemBtns {
    text-align: right;
    width: 100%;
    @media screen and (min-width: 650px){
        width: 140px;
    }
}

.manItem button, .manItemBtn {
    overflow: hidden;
    width: 40px;
    height: 40px;
    display: inline-block;
    background-position: center center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    border: 0px;
    background-color: $c-white;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 6px;
    &:last-child {
        margin-right: 0px;
    }
}


.manItem_btnEdit {
    background-image: url(./img/edit.svg);
}
.manItem_btnCopy {
    background-image: url(./img/copy.svg);
}
.manItem_btnDelete {
    background-image: url(./img/delete.svg);
}


.manAll {
    padding: 15px;
    border-radius: 5px;
    background-color: rgba(79, 79, 79, 0.25);
    color: $c-black;
    display: flex;
    gap: 10px;
    font-size: 1.5rem;
    flex-wrap: wrap;
    margin-bottom: 10px;
    justify-content: space-between;
    @media screen and (min-width: 650px){
        flex-wrap: nowrap;
    }
}
.manAll p {
    font-weight: 400;
    display: block;
    width: 100%;
    text-align: right;
    @media screen and (min-width: 650px){
        text-align: left;
        flex: 1;
        width: auto;
    }
    &:last-child {
        text-align: right;
    }
}

.manAll span {
    font-weight: 700;
}

.eventItemBtns {
    display: flex;
    margin-top: 10px;

}