/* main */

.hp {
    display: flex;
    min-height: calc(100vh - 115px);
    justify-content: center;
    align-items: center;
}

.login {
    width: 100%;
    max-width: 280px;
    padding: 40px;
    border-radius: 20px;
    border: 2px solid $c-back;
    background-color: $c-blue;
    box-shadow: 1px 2px 56px 0px rgba(0,0,0,0.2);

}

.login h1 {
    color: $c-white;
    margin-top: -20px;
}