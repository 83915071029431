/* PARALAX */
.paraX {
  opacity: 0;
}
.paraXNo {
  opacity: 1 !important;
}
@keyframes para {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes paraD {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes paraL {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes paraR {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
.paraAnim {
  animation: para 1s ease-in-out 0s 1 forwards;
}

.paraAnimD {
  animation: paraD 1s ease-in-out 0s 1 forwards;
}

.paraAnimL {
  animation: paraL 1s ease-in-out 0s 1 forwards;
}

.paraAnimR {
  animation: paraR 1s ease-in-out 0s 1 forwards;
}

.paraAnim-03 {
  opacity: 0;
  animation: para 1s ease-in-out 0.3s 1 forwards;
}

.paraAnim-03D {
  opacity: 0;
  animation: paraD 1s ease-in-out 0.3s 1 forwards;
}

.paraAnim-03L {
  opacity: 0;
  animation: paraL 1s ease-in-out 0.3s 1 forwards;
}

.paraAnim-03R {
  opacity: 0;
  animation: paraR 1s ease-in-out 0.3s 1 forwards;
}

.paraAnim-05 {
  opacity: 0;
  animation: para 1s ease-in-out 0.5s 1 forwards;
}

.paraAnim-05D {
  opacity: 0;
  animation: paraD 1s ease-in-out 0.5s 1 forwards;
}

.paraAnim-05L {
  opacity: 0;
  animation: paraL 1s ease-in-out 0.5s 1 forwards;
}

.paraAnim-05R {
  opacity: 0;
  animation: paraR 1s ease-in-out 0.5s 1 forwards;
}

.paraAnim-08 {
  opacity: 0;
  animation: para 1s ease-in-out 0.8s 1 forwards;
}

.paraAnim-08D {
  opacity: 0;
  animation: paraD 1s ease-in-out 0.8s 1 forwards;
}

.paraAnim-08L {
  opacity: 0;
  animation: paraL 1s ease-in-out 0.8s 1 forwards;
}

.paraAnim-08R {
  opacity: 0;
  animation: paraR 1s ease-in-out 0.8s 1 forwards;
}
@keyframes paraOpaOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.paraOpacity10 {
  animation: paraOpaOut 1s ease-in-out 0s 1 forwards;
}

@keyframes paraOpaIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.paraOpacity01 {
  animation: paraOpaIn 1s ease-in-out 0s 1 forwards;
}

/* PARALAX STOP */
