/* BUTTONS */
.btn {
    width: auto;
    display: inline-block;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.25rem;;
    padding: 10px 10px;
    background-color: rgba($c-black,1);
    border: 2px solid $c-black;
    color: $c-white;
    @include trans();
    cursor: pointer;
    border-radius: 8px;
    &:hover {
        @include trans();
        color: $c-black;
        background-color: rgba($c-black,0);
    }
}
.btn:disabled  {
    opacity: 0.2;
}

.btnW100 {
    width: 100%;;
}