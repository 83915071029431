/* VARIABLES */

/* FONTS */
$fontTxt: "Roboto", sans-serif;


/* COLORS */
$c-white: rgba(255,255,255,1); //#FFFFFF
$c-txt: rgba(0,0,0,1); //#000000
$c-yellow: rgba(255,237,0,1); //#FFED00
$c-black: rgba(0,0,0,1); //#000000
$c-blue: rgb(64, 196, 224);

$c-grey: rgb(79, 79, 79); //#53575f
$c-grey-light: rgb(135, 135, 135);
$c-green: rgba(194,229,222,1); //#C2E5DE
$c-back: rgba(79, 79, 79, 0.05);
/* border-radius */
$border-radius: 4px;

/* paddings */
$p-basic: 10px;

/* widths */
$w-max: 1280px;

$menuToggle: 651px;


/* widths */
//basic height for button, and inputs
$h-basic: 40px;

/* annimation */
$ct-ease-swift: cubic-bezier(0, 0.1, 0.3, 1);
$ct-ease-ease-out: cubic-bezier(0, 0, 0.3, 1);
$ct-ease-ease-in-out: cubic-bezier(0.1, 0, 0.2, 1);
