@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  

html,
body {
    font-size: 16px;
    padding: 0px 0px 0px 0px;
    margin: 0;
    font-family: $fontTxt;
    background-color: $c-back;
    color: $c-grey;
}

html {
    scroll-behavior: smooth;
}

body {
    
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}
main {
    flex: 1;
}
p {
    margin: 0;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}


.hidden {
  display: none;
}


strong {
    font-family: $fontTxt;
    font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
    text-align: center;
    margin: 0px 0px 20px 0px;
}

/* KONTAIERY */
.container_thin {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    max-width: 590px;
    
}
.container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    max-width: 1140px;
    
}



.container__noPadd {
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
    @media only screen and (min-width: 1366px) {
        max-width: 1280px;
        padding-right: 0px;
        padding-left: 0px;
    }
}

.container__basicPad {
    padding-top: 3 * $p-basic;
    padding-bottom: 5 * $p-basic;
    @media only screen and (max-width: 768px) {
        padding-top: 2 * $p-basic;
        padding-bottom: 4 * $p-basic;
    }
    @media only screen and (max-width: 640px) {
        padding-top: 2 * $p-basic;
        padding-bottom: 2 * $p-basic;
    }
}

.link-pink {
  color: $c-txt;
  font-family: $fontTxt;
  a {
    color: $c-txt;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

/* CLEARFIX */

.cf:before,
.cf:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
}

.cf:after {
    clear: both;
}

.cf {
    zoom: 1;
}

.mb-6 {
    margin-bottom: 6 * $p-basic;
}

.mb-4 {
    margin-bottom: 4 * $p-basic;
}

.mb-3 {
    margin-bottom: 3 * $p-basic;
}

.mb-2 {
    margin-bottom: 2 * $p-basic;
}

.mt-6 {
    margin-top: 6 * $p-basic;
}

.mt-4 {
    margin-top: 4 * $p-basic;
}

.mt-3 {
    margin-top: 3 * $p-basic;
}

.mt-2 {
    margin-top: 2 * $p-basic;
}

.ta-center {
    text-align: center;
}

footer {
    min-height: 40px;
}

/* MODAL */

.modal {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba($c-grey, 0.40);
    display: flex;
    align-items: center;
    align-content: center;
}

.modal__body {
    width: 100%;
    max-width: 700px;
    margin: (3 * $p-basic) auto (3 * $p-basic) auto;
    padding: 15px 30px 30px 30px;
    border-radius: $border-radius;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
    @media screen and (max-width: 560px) {
        padding: 12px 10px 10px 10px;
    }
    position: relative;
}

.modal__close {
    width: 100%;
    height: 24px;
    margin-bottom: 20px;
    background-image: url(./img/hamburger-x.svg);
    background-color: transparent;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 31px 24px;
    border: none;
    outline: none;
    opacity: 1;
    cursor: pointer;
    @include trans();
    &:hover {
        @include trans();
        opacity: 0.5;
    }
}

.modal__form {
    
    margin: 0;
    padding: 0;
}

.modal__hidden {
    display: none;
}

.modal__content h3 {

    text-transform: uppercase;
    margin-bottom: 2 * $p-basic;
}

.modal__content h2 {
    text-transform: uppercase;
    margin-bottom: 4.5 * $p-basic;

}

.modal__form__group {
    margin-bottom: 10px;
}
.modal__form__group__flex {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.modal__q {
    height: 56px;
    width: auto;
}
.modal__btn {
    width: 150px;
    background-color: #FFF;
    border: none;
    height: 56px;
    cursor: pointer;
    line-height: normal;

}

.modal__form input[type="text"],
.modal__form input[type="email"],
.modal__form input[type="tel"] {
    border-radius: 0px;
    padding: 16px 19px;
    border: none;
    color: $c-grey;
    box-sizing: border-box;
    width: 100%;
}

.modal__textarea {
    border-radius: 0px;
    padding: 26px 29px;
    height: 300px;
    box-sizing: border-box;
    width: 100%;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
   color: rgba($c-grey, 0.80);
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba($c-grey, 0.80);
}

:-ms-input-placeholder {
    /* IE 10+ */
   color: rgba($c-grey, 0.80);
}

:-moz-placeholder {
    /* Firefox 18- */
    color: rgba($c-grey, 0.80);
}

.map {
    min-height: 80vh;
    @media screen and (max-width: 768px) {
         min-height: 80vh;
    }

    img {
      display: block;
    }

}
.map > div {
  width: 100% !important;
  height: 100% !important;
}
