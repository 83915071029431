/* FORMS */
.er {
    background-color: red;
    color: #FFF;
    margin-bottom: 20px;
    padding: 20px;
    font-weight: bold;
    border-radius: 5px;
}
.ch {
    background-color: #12e452;
    color: #000;
    border-radius: 5px;
    margin-bottom: 25px;
    padding: 20px;
    font-weight: bold;
}



.form__in {
  
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 5px 10px;
    border: 1px solid #000 ;
    color: $c-grey;
    width: calc(100% );
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
}

.form__in::placeholder {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  opacity: 0.6;  
  text-align: center;
}


.form__ta {
    width: 100%;
    height: 300px;
    display: block;
}

input[type="radio"] + label {
    display: inline-block;
    padding: 5px 0px 5px 25px;
   background-image: url('./img/radioOff.svg');
    background-size: 15px 15px;
    color: $c-black;
    background-repeat: no-repeat;
    background-position: left top 8px;
    font-size: 0.9375rem;
    line-height: 1.375rem;
    font-weight: 500;
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 0px;
    width: auto;
  }
  input[type="radio"] + label:last-child {
    margin-right: 0px;
  }
  
  input[type="radio"]:disabled + label {
    opacity: 0.4;
  }
  
  input[type="radio"]:checked + label {
    background-image: url('./img/radioOn.svg');
    display: inline-block;
  }
  
  input[type="radio"] {
    display: none;
  }

  input[type="checkbox"] + label {
    display: inline-block;
    padding: 0px 0px 0px 35px;
   background-image: url('./img/checkOff.svg');
    background-size: 15px 15px;
    color: $c-black;
    background-repeat: no-repeat;
    background-position: left top 0px;
    font-size: 0.625rem;
    line-height: 0.793rem;
    font-weight: 400;
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 0px;
    width: auto;
  }
  input[type="checkbox"] + label:last-child {
    margin-right: 0px;
  }
  
  input[type="checkbox"]:disabled + label {
    opacity: 0.4;
  }
  
  input[type="checkbox"]:checked + label {
    background-image: url('./img/checkOn.svg');
    display: inline-block;
  }
  
  input[type="checkbox"] {
    display: none;
  }