/* KIDS */

.btn_logout {
    position: absolute;
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    top: 16px;
    right: 16px;
    font-weight: 900;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.choose_date_inp  {
    margin: 10px auto 30px auto;
    display: block;
    text-align: center;
}

.sigEl {
    margin-bottom: 30px;

    canvas {
        background-color: white;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    label {
        display: block;
        margin-bottom: 10px;
    }

    .btn {
        display: block;
    }
}

.record {
    
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 17px;
    background-color: white;
}

.timeOk {
    border: 3px solid #00e23a;
}

.timeOver {
    border: 3px solid #e2008a;
}

.timeBack {
    opacity: 0.6;
    border: 3px solid black;
}
.timeBackOver {
    opacity: 0.6;
    border: 3px solid #9c6694;
}

.kidsWrap {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 50px;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    .manItem_btnDelete {
        position: relative;
        top: 9px;
        width: 43px;
        height: 43px;
        background-size: 43px 43px;
        margin-left: 25px;
    }
}    
#checkInfo {
    font-weight: 900;
    display: inline-block;
}
.checkInfoWrap {
    text-align: center;
    margin-bottom: 15px;
}

.occupation {
    width: 160px;
    padding: 17px;
    background-color: $c-blue;
    border-radius: 7px;
    margin: 0px auto 12px auto;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    box-shadow: 1px 2px 56px 0px rgba(0, 0, 0, 0.2);
    color: $c-white;
}

.full {
    background-color: #ca0000;
}

.occupationLabel {
    text-align: center;
    margin-bottom: 5px;
    font-weight: 700;
}

.infoAll {
    background-color: white;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 20px;
}

#kidsAll, #kidsOverTime {
    font-size: 1.8rem;
    display: inline-block;
    margin-right: 5px;
    font-weight: 900;
}

.hidden {
    display: none;
}